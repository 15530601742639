body.base
    .mat-mdc-form-field.mat-form-field-appearance-fill
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mat-mdc-form-field-infix {
    min-height: 40px;
}

body.base .company-field {
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}
body.base .currency-field {
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

body.base .custom-link-field {
    .mat-mdc-text-field-wrapper {
        height: 40px;
        line-height: 40px;
        background-color: rgb(255 255 255 / 1) !important;
        .mat-mdc-form-field-flex {
            height: 40px;
        }
        .mat-mdc-form-field-infix {
            height: auto;
            min-height: 40px;
            display: flex;
            align-items: center;
            padding: 0 8px;
            box-sizing: border-box;
        }
    }
    .mat-mdc-floating-label {
        transform: translateY(-50%);
        top: 50%;
        line-height: 40px;
    }
}
body.base .mat-form-field-disabled {
    .mdc-floating-label,
    .mat-mdc-floating-label {
        color: black !important;
    }
}

body.base .wrap-code {
    display: flex;
    .mat-mdc-text-field-wrapper {
        padding: 0;
    }
    .mdc-text-field--outlined
        .mdc-notched-outline
        .mdc-notched-outline__trailing {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
body.base .fix-left-field {
    .mdc-text-field--outlined
        .mdc-notched-outline
        .mdc-notched-outline__leading {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
    }
}

body.base .cdk-overlay-pane {
    width: auto;
    min-width: 190px;
}

body.base .selected-field {
    .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-value-text {
        display: none !important;
    }
}
